




import { defineComponent, computed } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'Service',
  components: {
    AfterClassServiceDesk: () => import('./desk.vue'),
    AfterClassServicePhone: () => import('./phone.vue')
  },
  setup() {
    const serviceType = computed(() =>
      AppModule.device === DeviceType.Desktop
        ? 'AfterClassServiceDesk'
        : 'AfterClassServicePhone'
    )
    return { serviceType }
  }
})
